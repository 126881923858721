export const RecentProjectsList = [
    // Blivix Restaurant E-Commerce
    {
        "id": 3,
        "title": {
            "en": "Blivix Restaurant E-Commerce",
            "tr": "Blivix Restoran E-Ticaret",
            "fa": "تجارت الکترونیک رستوران Blivix"
        },
        "sub": {
            "en": "Responsive E-Commerce Website with Language Selector",
            "tr": "Dil Seçicili Duyarlı E-Ticaret Web Sitesi",
            "fa": "وب سایت تجارت الکترونیک واکنش گرا با انتخابگر زبان"
        },
        "description": {
            "en": "Restaurant e-commerce website with a language selector enables customers to explore the menu, place orders, and select their preferred language for navigation. The website simplifies the ordering process, allowing users to browse through menu items, make selections, and submit orders seamlessly. With the language selector feature, the website caters to diverse linguistic preferences, enhancing accessibility and user experience.",
            "tr": "Dil seçicisi olan restoran e-ticaret web sitesi, müşterilerin menüyü keşfetmesini, sipariş vermesini ve gezinme için tercih ettikleri dili seçmesini sağlar. Web sitesi sipariş verme sürecini basitleştirir, kullanıcıların menü öğelerine göz atmasını, seçimler yapmasını ve siparişleri sorunsuz bir şekilde göndermesini sağlar. Dil seçici özelliğiyle web sitesi çeşitli dil tercihlerine hitap ederek erişilebilirliği ve kullanıcı deneyimini geliştirir.",
            "fa": "وب‌سایت تجارت الکترونیک رستوران با انتخابگر زبان، مشتریان را قادر می‌سازد تا منو را بررسی کنند، سفارش دهند و زبان دلخواه خود را برای پیمایش انتخاب کنند. این وب‌سایت فرآیند سفارش را ساده می‌کند و به کاربران این امکان را می‌دهد که آیتم‌های منو را مرور کنند، انتخاب کنند و سفارش‌ها را به صورت یکپارچه ارسال کنند. با ویژگی انتخاب زبان، وب سایت به اولویت های زبانی متنوع پاسخ می دهد و دسترسی و تجربه کاربر را افزایش می دهد."
        },
        "feature": {
            "en": [
                "Fully Responsive",
                "Live Product Updates",
                "Advanced Filtering and Sorting",
                "E-commerce Functionality",
                "Fluid Layout",
                "Flexible Grids",
                "Media Queries",
                "Flexible Images",
                "Viewport Meta Tag",
                "Mobile Navigation",
                "Breakpoints",
                "Touchscreen Optimization",
                "Performance Optimization",
                "Device Compatibility",
                "Fluid Typography",
                "Flexible Forms",
                "CSS Flexbox and Grid Layouts",
                "Device Orientation Support",
                "Responsive Images",
                "Cross-Browser Compatibility",
                "Flexible Text",
                "Performance Budgeting",
                "Touch Gestures and Interactions",
                "Responsive Typography",
                "Scalable Vector Graphics",
                "Dynamic Content Loading",
                "Adaptive Navigation Menus",
                "Fluid Animations and Transitions",
                "Form Optimization",
                "Search Engine",
                "Multi-platform Compatibility",
                "Accessibility Features",
                "Menu Display",
                "Online Ordering System",
                "User Account Management",
                "Product Details",
                "Product Zoom",
                "Pagination",
                "Testimonials",
                "Contact Information",
                "Neat, clean and simple design",
                "Google Fonts",
                "Layout – Responsive",
                "User-Friendly Code",
                "Easy to customize",
                "SEO Optimization",
                "SSL Certificate",
                "SSL Security"
            ],
            "tr": [
                "Tamamen Duyarlı",
                "Canlı Ürün Güncellemeleri",
                "Gelişmiş Filtreleme ve Sıralama",
                "E-ticaret Fonksiyonu",
                "Akıcı Düzen",
                "Esnek Izgaralar",
                "Medya Sorguları",
                "Esnek Görseller",
                "Viewport Meta Etiketi",
                "Mobil Navigasyon",
                "Kırılma Noktaları",
                "Dokunmatik Ekran Optimizasyonu",
                "Performans Optimizasyonu",
                "Cihaz Uyumluluğu",
                "Akıcı Tipografi",
                "Esnek Formlar",
                "CSS Flexbox ve Grid Düzenleri",
                "Cihaz Yönlendirme Desteği",
                "Duyarlı Görseller",
                "Tarayıcılar Arası Uyumluluk",
                "Esnek Metin",
                "Performans Bütçelemesi",
                "Dokunmatik Hareketler ve Etkileşimler",
                "Duyarlı Tipografi",
                "Ölçeklenebilir Vektör Grafikler",
                "Dinamik İçerik Yükleme",
                "Uyarlanabilir Navigasyon Menüler",
                "Akıcı Animasyonlar ve Geçişler",
                "Form Optimizasyonu",
                "Arama Motoru",
                "Çoklu Platform Uyumluluğu",
                "Erişilebilirlik Özellikleri",
                "Menü Görüntüleme",
                "Online Sipariş Sistemi",
                "Kullanıcı Hesap Yönetimi",
                "Ürün Detayları",
                "Ürün Yakınlaştırma",
                "Sayfalama",
                "Referanslar",
                "İletişim Bilgileri",
                "Temiz ve basit tasarım",
                "Google Yazı Tipleri",
                "Düzen – Duyarlı",
                "Kullanıcı Dostu Kod",
                "Kolay özelleştirme",
                "SEO Optimizasyonu",
                "SSL Sertifikası",
                "SSL Güvenliği"
            ],
            "fa": [
                "کاملاً واکنش‌گرا",
                "بروزرسانی محصولات به صورت زنده",
                "فیلتر کردن و مرتب‌سازی پیشرفته",
                "قابلیت تجارت الکترونیک",
                "چیدمان سیال",
                "شبکه‌های انعطاف‌پذیر",
                "کوئری‌های رسانه",
                "تصاویر انعطاف‌پذیر",
                "تگ متا ویوپورت",
                "ناوبری موبایل",
                "نقاط شکست",
                "بهینه‌سازی صفحه لمسی",
                "بهینه‌سازی عملکرد",
                "سازگاری با دستگاه‌ها",
                "تایپوگرافی سیال",
                "فرم‌های انعطاف‌پذیر",
                "چیدمان‌های Flexbox و Grid CSS",
                "پشتیبانی از جهت‌یابی دستگاه",
                "تصاویر واکنش‌گرا",
                "سازگاری با مرورگرهای مختلف",
                "متن انعطاف‌پذیر",
                "بودجه‌بندی عملکرد",
                "حرکات و تعاملات لمسی",
                "تایپوگرافی واکنش‌گرا",
                "گرافیک‌های برداری مقیاس‌پذیر",
                "بارگذاری محتوای پویا",
                "منوهای ناوبری تطبیقی",
                "انیمیشن‌ها و انتقالات سیال",
                "بهینه‌سازی فرم",
                "موتور جستجو",
                "سازگاری با چندین پلتفرم",
                "ویژگی‌های دسترسی",
                "نمایش منو",
                "سیستم سفارش آنلاین",
                "مدیریت حساب کاربری",
                "جزئیات محصول",
                "بزرگنمایی محصول",
                "صفحه‌بندی",
                "نظرات مشتریان",
                "اطلاعات تماس",
                "طراحی تمیز و ساده",
                "فونت‌های گوگل",
                "چیدمان – واکنش‌گرا",
                "کد کاربرپسند",
                "قابلیت سفارشی‌سازی آسان",
                "بهینه‌سازی سئو",
                "گواهی SSL",
                "امنیت SSL"
            ]
        },
        "livePreview": "https://nacode.co",
        "img": [
            "https://i.postimg.cc/7YkcQ9LC/Main.png",
            "https://i.postimg.cc/FFL561g6/Desktop-1.png",
            "https://i.postimg.cc/65xJxwMp/Desktop-2.png",
            "https://i.postimg.cc/sXtFXRyK/Desktop-4.png",
            "https://i.postimg.cc/xTGr07Zy/Desktop-5.png",
            "https://i.postimg.cc/dVnzCCwh/Desktop-6.png",
            "https://i.postimg.cc/cJHPBJLX/Desktop-7.png",
            "https://i.postimg.cc/0y9TrnRg/Desktop-8.png",
            "https://i.postimg.cc/XYNPnvd4/Mobile-1.png",
            "https://i.postimg.cc/DyPDVGWx/Mobile-2.png",
            "https://i.postimg.cc/t4rMJHNP/Mobile-3.png",
            "https://i.postimg.cc/fbJg0g8P/Mobile-4.png",
            "https://i.postimg.cc/1z270cT3/Mobile-5.png",
            "https://i.postimg.cc/ZRN7wvtF/Mobile-6.png"
        ],
        "mp4": ""
    }

    // Flerko Restaurant E-Commerce
    ,
    {
        "id": 4,
        "title": {
            "en": "Flerko Restaurant E-Commerce",
            "tr": "Flerko Restoran E-Ticaret",
            "fa": "تجارت الکترونیک رستوران Flerko"
        },
        "sub": {
            "en": "Responsive E-Commerce Website",
            "tr": "Duyarlı E-Ticaret Web Sitesi",
            "fa": "وب‌سایت تجارت الکترونیک واکنش‌گرا"
        },
        "description": {
            "en": "",
            "tr": "",
            "fa": ""
        },
        "feature": {
            "en": [
                "Fully Responsive",
                "Live Product Updates",
                "Advanced Filtering and Sorting",
                "E-commerce Functionality",
                "Fluid Layout",
                "Flexible Grids",
                "Media Queries",
                "Flexible Images",
                "Viewport Meta Tag",
                "Mobile Navigation",
                "Breakpoints",
                "Touchscreen Optimization",
                "Performance Optimization",
                "Device Compatibility",
                "Fluid Typography",
                "Flexible Forms",
                "CSS Flexbox and Grid Layouts",
                "Device Orientation Support",
                "Responsive Images",
                "Cross-Browser Compatibility",
                "Flexible Text",
                "Performance Budgeting",
                "Touch Gestures and Interactions",
                "Responsive Typography",
                "Scalable Vector Graphics",
                "Dynamic Content Loading",
                "Adaptive Navigation Menus",
                "Fluid Animations and Transitions",
                "Form Optimization",
                "Search Engine",
                "Multi-platform Compatibility",
                "Accessibility Features",
                "Menu Display",
                "Online Ordering System",
                "User Account Management",
                "Product Details",
                "Product Zoom",
                "Pagination",
                "Testimonials",
                "Contact Information",
                "Neat, clean and simple design",
                "Google Fonts",
                "Layout – Responsive",
                "User-Friendly Code",
                "Easy to customize",
                "SEO Optimization",
                "SSL Certificate",
                "SSL Security"
            ],
            "tr": [
                "Tamamen Duyarlı",
                "Canlı Ürün Güncellemeleri",
                "Gelişmiş Filtreleme ve Sıralama",
                "E-ticaret Fonksiyonu",
                "Akıcı Düzen",
                "Esnek Izgaralar",
                "Medya Sorguları",
                "Esnek Görseller",
                "Viewport Meta Etiketi",
                "Mobil Navigasyon",
                "Kırılma Noktaları",
                "Dokunmatik Ekran Optimizasyonu",
                "Performans Optimizasyonu",
                "Cihaz Uyumluluğu",
                "Akıcı Tipografi",
                "Esnek Formlar",
                "CSS Flexbox ve Grid Düzenleri",
                "Cihaz Yönlendirme Desteği",
                "Duyarlı Görseller",
                "Tarayıcılar Arası Uyumluluk",
                "Esnek Metin",
                "Performans Bütçelemesi",
                "Dokunmatik Hareketler ve Etkileşimler",
                "Duyarlı Tipografi",
                "Ölçeklenebilir Vektör Grafikler",
                "Dinamik İçerik Yükleme",
                "Uyarlanabilir Navigasyon Menüler",
                "Akıcı Animasyonlar ve Geçişler",
                "Form Optimizasyonu",
                "Arama Motoru",
                "Çoklu Platform Uyumluluğu",
                "Erişilebilirlik Özellikleri",
                "Menü Görüntüleme",
                "Online Sipariş Sistemi",
                "Kullanıcı Hesap Yönetimi",
                "Ürün Detayları",
                "Ürün Yakınlaştırma",
                "Sayfalama",
                "Referanslar",
                "İletişim Bilgileri",
                "Temiz ve basit tasarım",
                "Google Yazı Tipleri",
                "Düzen – Duyarlı",
                "Kullanıcı Dostu Kod",
                "Kolay özelleştirme",
                "SEO Optimizasyonu",
                "SSL Sertifikası",
                "SSL Güvenliği"
            ],
            "fa": [
                "کاملاً واکنش‌گرا",
                "بروزرسانی محصولات به صورت زنده",
                "فیلتر کردن و مرتب‌سازی پیشرفته",
                "قابلیت تجارت الکترونیک",
                "چیدمان سیال",
                "شبکه‌های انعطاف‌پذیر",
                "کوئری‌های رسانه",
                "تصاویر انعطاف‌پذیر",
                "تگ متا ویوپورت",
                "ناوبری موبایل",
                "نقاط شکست",
                "بهینه‌سازی صفحه لمسی",
                "بهینه‌سازی عملکرد",
                "سازگاری با دستگاه‌ها",
                "تایپوگرافی سیال",
                "فرم‌های انعطاف‌پذیر",
                "چیدمان‌های Flexbox و Grid CSS",
                "پشتیبانی از جهت‌یابی دستگاه",
                "تصاویر واکنش‌گرا",
                "سازگاری با مرورگرهای مختلف",
                "متن انعطاف‌پذیر",
                "بودجه‌بندی عملکرد",
                "حرکات و تعاملات لمسی",
                "تایپوگرافی واکنش‌گرا",
                "گرافیک‌های برداری مقیاس‌پذیر",
                "بارگذاری محتوای پویا",
                "منوهای ناوبری تطبیقی",
                "انیمیشن‌ها و انتقالات سیال",
                "بهینه‌سازی فرم",
                "موتور جستجو",
                "سازگاری با چندین پلتفرم",
                "ویژگی‌های دسترسی",
                "نمایش منو",
                "سیستم سفارش آنلاین",
                "مدیریت حساب کاربری",
                "جزئیات محصول",
                "بزرگنمایی محصول",
                "صفحه‌بندی",
                "نظرات مشتریان",
                "اطلاعات تماس",
                "طراحی تمیز و ساده",
                "فونت‌های گوگل",
                "چیدمان – واکنش‌گرا",
                "کد کاربرپسند",
                "قابلیت سفارشی‌سازی آسان",
                "بهینه‌سازی سئو",
                "گواهی SSL",
                "امنیت SSL"
            ]
        },
        "livePreview": "https://nacode.co",
        "img": [
            "https://i.postimg.cc/Nfb39n66/flerko-en-home.png",
            "https://i.postimg.cc/MGfNLKh5/flerko-en-desktop.png",
            "https://i.postimg.cc/YCsT8myj/flerko-en-mobile-01.png",
            "https://i.postimg.cc/4xdrz1Pr/flerko-en-mobile-02.png",
            "https://i.postimg.cc/C1yXpp0g/flerko-en-mobile-03.png",
            "https://i.postimg.cc/PxmctVf8/flerko-en-mobile-04.png",

            "https://i.postimg.cc/nrq0KX9j/flerko-tr-home.png",
            "https://i.postimg.cc/NM3dr6Fv/flerko-tr-desktop.png",
            "https://i.postimg.cc/HWMZ1hvY/flerko-tr-mobile-01.png",
            "https://i.postimg.cc/fThHzf1x/flerko-tr-mobile-02.png",
            "https://i.postimg.cc/wTcw7sdn/flerko-tr-mobile-03.png",
            "https://i.postimg.cc/0ykcm2kj/flerko-tr-mobile-04.png",

            "https://i.postimg.cc/50dzBnBg/flerko-fa-home.png",
            "https://i.postimg.cc/cCT8NWVk/flerko-fa-desktop.png",
            "https://i.postimg.cc/j2RJ92kJ/flerko-fa-mobile-01.png",
            "https://i.postimg.cc/B6TKMHN0/flerko-fa-mobile-02.png",
            "https://i.postimg.cc/1RKFF0f0/flerko-fa-mobile-03.png",
            "https://i.postimg.cc/kXXbvVb0/flerko-fa-mobile-04.png"
        ],
        "mp4": ""
    }

    // Quazbit Restaurant E-Commerce
    ,
    {
        "id": 5,
        "title": {
            "en": "Flerko Restaurant E-Commerce",
            "tr": "Flerko Restoran E-Ticaret",
            "fa": "تجارت الکترونیک رستوران Flerko"
        },
        "sub": {
            "en": "Responsive E-Commerce Website",
            "tr": "Duyarlı E-Ticaret Web Sitesi",
            "fa": "وب‌سایت تجارت الکترونیک واکنش‌گرا"
        },
        "description": {
            "en": "",
            "tr": "",
            "fa": ""
        },
        "feature": {
            "en": [
                "Fully Responsive",
                "Live Product Updates",
                "Advanced Filtering and Sorting",
                "E-commerce Functionality",
                "Fluid Layout",
                "Flexible Grids",
                "Media Queries",
                "Flexible Images",
                "Viewport Meta Tag",
                "Mobile Navigation",
                "Breakpoints",
                "Touchscreen Optimization",
                "Performance Optimization",
                "Device Compatibility",
                "Fluid Typography",
                "Flexible Forms",
                "CSS Flexbox and Grid Layouts",
                "Device Orientation Support",
                "Responsive Images",
                "Cross-Browser Compatibility",
                "Flexible Text",
                "Performance Budgeting",
                "Touch Gestures and Interactions",
                "Responsive Typography",
                "Scalable Vector Graphics",
                "Dynamic Content Loading",
                "Adaptive Navigation Menus",
                "Fluid Animations and Transitions",
                "Form Optimization",
                "Search Engine",
                "Multi-platform Compatibility",
                "Accessibility Features",
                "Menu Display",
                "Online Ordering System",
                "User Account Management",
                "Product Details",
                "Product Zoom",
                "Pagination",
                "Testimonials",
                "Contact Information",
                "Neat, clean and simple design",
                "Google Fonts",
                "Layout – Responsive",
                "User-Friendly Code",
                "Easy to customize",
                "SEO Optimization",
                "SSL Certificate",
                "SSL Security"
            ],
            "tr": [
                "Tamamen Duyarlı",
                "Canlı Ürün Güncellemeleri",
                "Gelişmiş Filtreleme ve Sıralama",
                "E-ticaret Fonksiyonu",
                "Akıcı Düzen",
                "Esnek Izgaralar",
                "Medya Sorguları",
                "Esnek Görseller",
                "Viewport Meta Etiketi",
                "Mobil Navigasyon",
                "Kırılma Noktaları",
                "Dokunmatik Ekran Optimizasyonu",
                "Performans Optimizasyonu",
                "Cihaz Uyumluluğu",
                "Akıcı Tipografi",
                "Esnek Formlar",
                "CSS Flexbox ve Grid Düzenleri",
                "Cihaz Yönlendirme Desteği",
                "Duyarlı Görseller",
                "Tarayıcılar Arası Uyumluluk",
                "Esnek Metin",
                "Performans Bütçelemesi",
                "Dokunmatik Hareketler ve Etkileşimler",
                "Duyarlı Tipografi",
                "Ölçeklenebilir Vektör Grafikler",
                "Dinamik İçerik Yükleme",
                "Uyarlanabilir Navigasyon Menüler",
                "Akıcı Animasyonlar ve Geçişler",
                "Form Optimizasyonu",
                "Arama Motoru",
                "Çoklu Platform Uyumluluğu",
                "Erişilebilirlik Özellikleri",
                "Menü Görüntüleme",
                "Online Sipariş Sistemi",
                "Kullanıcı Hesap Yönetimi",
                "Ürün Detayları",
                "Ürün Yakınlaştırma",
                "Sayfalama",
                "Referanslar",
                "İletişim Bilgileri",
                "Temiz ve basit tasarım",
                "Google Yazı Tipleri",
                "Düzen – Duyarlı",
                "Kullanıcı Dostu Kod",
                "Kolay özelleştirme",
                "SEO Optimizasyonu",
                "SSL Sertifikası",
                "SSL Güvenliği"
            ],
            "fa": [
                "کاملاً واکنش‌گرا",
                "بروزرسانی محصولات به صورت زنده",
                "فیلتر کردن و مرتب‌سازی پیشرفته",
                "قابلیت تجارت الکترونیک",
                "چیدمان سیال",
                "شبکه‌های انعطاف‌پذیر",
                "کوئری‌های رسانه",
                "تصاویر انعطاف‌پذیر",
                "تگ متا ویوپورت",
                "ناوبری موبایل",
                "نقاط شکست",
                "بهینه‌سازی صفحه لمسی",
                "بهینه‌سازی عملکرد",
                "سازگاری با دستگاه‌ها",
                "تایپوگرافی سیال",
                "فرم‌های انعطاف‌پذیر",
                "چیدمان‌های Flexbox و Grid CSS",
                "پشتیبانی از جهت‌یابی دستگاه",
                "تصاویر واکنش‌گرا",
                "سازگاری با مرورگرهای مختلف",
                "متن انعطاف‌پذیر",
                "بودجه‌بندی عملکرد",
                "حرکات و تعاملات لمسی",
                "تایپوگرافی واکنش‌گرا",
                "گرافیک‌های برداری مقیاس‌پذیر",
                "بارگذاری محتوای پویا",
                "منوهای ناوبری تطبیقی",
                "انیمیشن‌ها و انتقالات سیال",
                "بهینه‌سازی فرم",
                "موتور جستجو",
                "سازگاری با چندین پلتفرم",
                "ویژگی‌های دسترسی",
                "نمایش منو",
                "سیستم سفارش آنلاین",
                "مدیریت حساب کاربری",
                "جزئیات محصول",
                "بزرگنمایی محصول",
                "صفحه‌بندی",
                "نظرات مشتریان",
                "اطلاعات تماس",
                "طراحی تمیز و ساده",
                "فونت‌های گوگل",
                "چیدمان – واکنش‌گرا",
                "کد کاربرپسند",
                "قابلیت سفارشی‌سازی آسان",
                "بهینه‌سازی سئو",
                "گواهی SSL",
                "امنیت SSL"
            ]
        },
        "livePreview": "https://nacode.co",
        "img": [
            "https://i.postimg.cc/1zJx6j1h/quazbit-en-home-01.png",
            "https://i.postimg.cc/hjjk01KJ/quazbit-en-home-02.png",
            "https://i.postimg.cc/QCKv2N9h/quazbit-en-desktop-01.png",
            "https://i.postimg.cc/DZMtp4zx/quazbit-en-desktop-02.png",
            "https://i.postimg.cc/QVLvhL15/quazbit-en-mobile-01.png",
            "https://i.postimg.cc/tR1TqhFC/quazbit-en-mobile-02.png",
            "https://i.postimg.cc/BZdvXXC5/quazbit-en-mobile-03.png",
            "https://i.postimg.cc/Z5mKx22C/quazbit-en-mobile-04.png",
            "https://i.postimg.cc/kgyM1wPw/quazbit-en-mobile-05.png",
            "https://i.postimg.cc/BvQSdDq3/quazbit-en-mobile-06.png",

            "https://i.postimg.cc/P50y7bbp/quazbit-tr-home-01.png",
            "https://i.postimg.cc/FR4Zr9Bh/quazbit-tr-home-02.png",
            "https://i.postimg.cc/W1dSSYZc/quazbit-tr-desktop-01.png",
            "https://i.postimg.cc/fbXCQ35H/quazbit-tr-desktop-02.png",
            "https://i.postimg.cc/63fhTDkn/quazbit-tr-mobile-01.png",
            "https://i.postimg.cc/SRZ7fcNK/quazbit-tr-mobile-02.png",
            "https://i.postimg.cc/5y9SzsJG/quazbit-tr-mobile-03.png",
            "https://i.postimg.cc/rygJT2CL/quazbit-tr-mobile-04.png",
            "https://i.postimg.cc/QxtqxY0K/quazbit-tr-mobile-05.png",
            "https://i.postimg.cc/8P0mFd0K/quazbit-tr-mobile-06.png",

            "https://i.postimg.cc/76hLTzc9/quazbit-fa-home-01.png",
            "https://i.postimg.cc/ThM2S4PN/quazbit-fa-home-02.png",
            "https://i.postimg.cc/dQ78bZ1G/quazbit-fa-mobile-01.png",
            "https://i.postimg.cc/NGW1BG9S/quazbit-fa-mobile-02.png",
            "https://i.postimg.cc/fRsmSHvv/quazbit-fa-mobile-03.png",
            "https://i.postimg.cc/N0fXp5S9/quazbit-fa-mobile-04.png",
            "https://i.postimg.cc/T3cmVr7v/quazbit-fa-mobile-05.png",
            "https://i.postimg.cc/Pq3wcdn6/quazbit-fa-mobile-06.png",
        ],
        "mp4": ""
    }

    // Zilvix Restaurant E-Commerce
    ,
    {
        "id": 6,
        "title": {
            "en": "Zilvix Restaurant E-Commerce",
            "tr": "Zilvix Restoran E-Ticaret",
            "fa": "تجارت الکترونیک رستوران Zilvix"
        },
        "sub": {
            "en": "Responsive E-Commerce Website",
            "tr": "Duyarlı E-Ticaret Web Sitesi",
            "fa": "وب‌سایت تجارت الکترونیک واکنش‌گرا"
        },
        "description": {
            "en": "",
            "tr": "",
            "fa": ""
        },
        "feature": {
            "en": [
                "Fully Responsive",
                "Live Product Updates",
                "Advanced Filtering and Sorting",
                "E-commerce Functionality",
                "Fluid Layout",
                "Flexible Grids",
                "Media Queries",
                "Flexible Images",
                "Viewport Meta Tag",
                "Mobile Navigation",
                "Breakpoints",
                "Touchscreen Optimization",
                "Performance Optimization",
                "Device Compatibility",
                "Fluid Typography",
                "Flexible Forms",
                "CSS Flexbox and Grid Layouts",
                "Device Orientation Support",
                "Responsive Images",
                "Cross-Browser Compatibility",
                "Flexible Text",
                "Performance Budgeting",
                "Touch Gestures and Interactions",
                "Responsive Typography",
                "Scalable Vector Graphics",
                "Dynamic Content Loading",
                "Adaptive Navigation Menus",
                "Fluid Animations and Transitions",
                "Form Optimization",
                "Search Engine",
                "Multi-platform Compatibility",
                "Accessibility Features",
                "Menu Display",
                "Online Ordering System",
                "User Account Management",
                "Product Details",
                "Product Zoom",
                "Pagination",
                "Testimonials",
                "Contact Information",
                "Neat, clean and simple design",
                "Google Fonts",
                "Layout – Responsive",
                "User-Friendly Code",
                "Easy to customize",
                "SEO Optimization",
                "SSL Certificate",
                "SSL Security"
            ],
            "tr": [
                "Tamamen Duyarlı",
                "Canlı Ürün Güncellemeleri",
                "Gelişmiş Filtreleme ve Sıralama",
                "E-ticaret Fonksiyonu",
                "Akıcı Düzen",
                "Esnek Izgaralar",
                "Medya Sorguları",
                "Esnek Görseller",
                "Viewport Meta Etiketi",
                "Mobil Navigasyon",
                "Kırılma Noktaları",
                "Dokunmatik Ekran Optimizasyonu",
                "Performans Optimizasyonu",
                "Cihaz Uyumluluğu",
                "Akıcı Tipografi",
                "Esnek Formlar",
                "CSS Flexbox ve Grid Düzenleri",
                "Cihaz Yönlendirme Desteği",
                "Duyarlı Görseller",
                "Tarayıcılar Arası Uyumluluk",
                "Esnek Metin",
                "Performans Bütçelemesi",
                "Dokunmatik Hareketler ve Etkileşimler",
                "Duyarlı Tipografi",
                "Ölçeklenebilir Vektör Grafikler",
                "Dinamik İçerik Yükleme",
                "Uyarlanabilir Navigasyon Menüler",
                "Akıcı Animasyonlar ve Geçişler",
                "Form Optimizasyonu",
                "Arama Motoru",
                "Çoklu Platform Uyumluluğu",
                "Erişilebilirlik Özellikleri",
                "Menü Görüntüleme",
                "Online Sipariş Sistemi",
                "Kullanıcı Hesap Yönetimi",
                "Ürün Detayları",
                "Ürün Yakınlaştırma",
                "Sayfalama",
                "Referanslar",
                "İletişim Bilgileri",
                "Temiz ve basit tasarım",
                "Google Yazı Tipleri",
                "Düzen – Duyarlı",
                "Kullanıcı Dostu Kod",
                "Kolay özelleştirme",
                "SEO Optimizasyonu",
                "SSL Sertifikası",
                "SSL Güvenliği"
            ],
            "fa": [
                "کاملاً واکنش‌گرا",
                "بروزرسانی محصولات به صورت زنده",
                "فیلتر کردن و مرتب‌سازی پیشرفته",
                "قابلیت تجارت الکترونیک",
                "چیدمان سیال",
                "شبکه‌های انعطاف‌پذیر",
                "کوئری‌های رسانه",
                "تصاویر انعطاف‌پذیر",
                "تگ متا ویوپورت",
                "ناوبری موبایل",
                "نقاط شکست",
                "بهینه‌سازی صفحه لمسی",
                "بهینه‌سازی عملکرد",
                "سازگاری با دستگاه‌ها",
                "تایپوگرافی سیال",
                "فرم‌های انعطاف‌پذیر",
                "چیدمان‌های Flexbox و Grid CSS",
                "پشتیبانی از جهت‌یابی دستگاه",
                "تصاویر واکنش‌گرا",
                "سازگاری با مرورگرهای مختلف",
                "متن انعطاف‌پذیر",
                "بودجه‌بندی عملکرد",
                "حرکات و تعاملات لمسی",
                "تایپوگرافی واکنش‌گرا",
                "گرافیک‌های برداری مقیاس‌پذیر",
                "بارگذاری محتوای پویا",
                "منوهای ناوبری تطبیقی",
                "انیمیشن‌ها و انتقالات سیال",
                "بهینه‌سازی فرم",
                "موتور جستجو",
                "سازگاری با چندین پلتفرم",
                "ویژگی‌های دسترسی",
                "نمایش منو",
                "سیستم سفارش آنلاین",
                "مدیریت حساب کاربری",
                "جزئیات محصول",
                "بزرگنمایی محصول",
                "صفحه‌بندی",
                "نظرات مشتریان",
                "اطلاعات تماس",
                "طراحی تمیز و ساده",
                "فونت‌های گوگل",
                "چیدمان – واکنش‌گرا",
                "کد کاربرپسند",
                "قابلیت سفارشی‌سازی آسان",
                "بهینه‌سازی سئو",
                "گواهی SSL",
                "امنیت SSL"
            ]
        },
        "livePreview": "https://nacode.co",
        "img": [
            "https://i.postimg.cc/05JVpBpg/zilvix-en-home.png",

            "https://i.postimg.cc/XJrQ12mC/zilvix-en-desktop-01.png",
            "https://i.postimg.cc/BvQhHZCN/zilvix-en-desktop-02.png",
            "https://i.postimg.cc/kMVpT4Pp/zilvix-en-desktop-03.png",
            "https://i.postimg.cc/ZRQ694f9/zilvix-en-desktop-04.png",

            "https://i.postimg.cc/VLQD4WRJ/zilvix-en-mobile-01.png",
            "https://i.postimg.cc/PxvQQCvb/zilvix-en-mobile-02.png",
            "https://i.postimg.cc/9FhYyFmT/zilvix-en-mobile-03.png",
            "https://i.postimg.cc/T32jH7W3/zilvix-en-mobile-04.png",

            "https://i.postimg.cc/wxQwVNnk/zilvix-tr-home.png",
            "https://i.postimg.cc/x8ntQjNH/zilvix-tr-desktop-01.png",
            "https://i.postimg.cc/gJvN1Ljm/zilvix-tr-desktop-02.png",
            "https://i.postimg.cc/Tw8tkJRt/zilvix-tr-desktop-03.png",
            "https://i.postimg.cc/TYQHRb9P/zilvix-tr-desktop-04.png",
            "https://i.postimg.cc/5N9nk0VW/zilvix-tr-mobile-01.png",
            "https://i.postimg.cc/TYkC86dL/zilvix-tr-mobile-02.png",
            "https://i.postimg.cc/zGVp8cvd/zilvix-tr-mobile-04.png",
            "https://i.postimg.cc/6pgc3fGB/zilvix-tr-mobile-04-1.png",

            "https://i.postimg.cc/bNspfqx4/zilvix-fa-home.png",
            "https://i.postimg.cc/QMLXqvWw/zilvix-fa-desktop-01.png",
            "https://i.postimg.cc/65356rXj/zilvix-fa-desktop-02.png",
            "https://i.postimg.cc/HnWWy57L/zilvix-fa-desktop-03.png",
            "https://i.postimg.cc/dVh168Nk/zilvix-fa-desktop-04.png",
            "https://i.postimg.cc/Cx80dJ6N/zilvix-fa-mobile-01.png",
            "https://i.postimg.cc/SQtbrHBd/zilvix-fa-mobile-02.png",
            "https://i.postimg.cc/qMhVZj0T/zilvix-fa-mobile-03.png",
            "https://i.postimg.cc/ZKGtkpKy/zilvix-fa-mobile-04.png"


        ],
        "mp4": ""
    }
]
