import React, {useState} from 'react';
import './Recent Projects.css';
import {Link} from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import {RecentProjectsList} from "../../Recent Projects List";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Keyboard, Navigation, Pagination } from 'swiper/modules';

function RecentProjects({scrollToTop, selectedLanguage}) {

    const [recentProjectsList] = useState(RecentProjectsList);

    return (
        <main>
            <section lang={selectedLanguage === 'fa' ? 'fa' : 'en'} className="recent_projects" id="projects">

                <h2 className="section_title-1 recent_project-title">
                    {selectedLanguage === 'en' ? 'Recent Projects'
                        : selectedLanguage === 'tr' ? 'Son Projeler'
                            : selectedLanguage === 'fa' ? 'پروژه های اخیر'
                                : null
                    }
                </h2>

                <div className="recent_projects-container container">

                    <Swiper
                        style={{
                            "--swiper-navigation-color": 'var(--black-color-light)',
                            "--swiper-pagination-color": 'var(--black-color-light)'
                        }}
                        spaceBetween={2}
                        initialSlide={2}
                        centeredSlides={true}
                        slidesPerView={1}
                        slidesPerGroupSkip={1}
                        grabCursor={true}
                        keyboard={{
                            enabled: true,
                        }}
                        breakpoints={{
                            768: {
                                slidesPerView: 2,
                                slidesPerGroup: 1,
                            },
                            1500: {
                                slidesPerView: 3,
                                slidesPerGroup: 1,
                            },
                        }}
                        navigation={true}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Keyboard, Navigation, Pagination]}
                        className="mySwiper"
                    >
                        {recentProjectsList.map((item) => (
                            <SwiperSlide className='slider' key={item.id}>
                                <Link to={`/project_details/${item.id}`}>
                                    <article className="recent-projects-card">
                                        <div className="recent_projects-image">
                                            {item.img.length > 0 && (
                                                <img src={item.img[0]} alt={item.title[selectedLanguage]} className="recent_projects-img"/>
                                            )}
                                            <div className="recent_projects-button button" onClick={scrollToTop}>
                                                <FaArrowRight className='arrow'/>
                                            </div>
                                        </div>

                                        <div className="recent-projects-content">
                                            <h3 className="recent_projects-title">
                                                {item.title[selectedLanguage]}
                                            </h3>
                                            <h3 className="recent_projects-subtitle">
                                                {item.sub[selectedLanguage]}
                                            </h3>
                                        </div>
                                    </article>
                                </Link>
                            </SwiperSlide>
                        ))}

                    </Swiper>

                </div>
            </section>
        </main>
    );
}

export default RecentProjects;
