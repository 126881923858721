const PrimaryEcommerceFeature = {
    "en": [
        // CSS and Styling Features
        "Fully Responsive",
        "Fluid Layout",
        "Flexible Grids",
        "Media Queries",
        "Flexible Images",
        "Viewport Meta Tag",
        "Mobile Navigation",
        "Breakpoints",
        "Touchscreen Optimization",
        "Device Compatibility",
        "Fluid Typography",
        "Flexible Forms",
        "CSS Flexbox and Grid Layouts",
        "Device Orientation Support",
        "Responsive Images",
        "Cross-Browser Compatibility",
        "Flexible Text",
        "Fluid Animations and Transitions",
        "Responsive Typography",
        "Scalable Vector Graphics",
        "Neat, clean and simple design",
        "Google Fonts",
        "Layout – Responsive",

        // E-commerce Features
        "E-commerce Functionality",
        "Live Product Updates",
        "Advanced Filtering and Sorting",
        "Performance Optimization",
        "Performance Budgeting",
        "Touch Gestures and Interactions",
        "Dynamic Content Loading",
        "Adaptive Navigation Menus",
        "Form Optimization",
        "Search Engine",
        "Multi-platform Compatibility",
        "Accessibility Features",
        "Menu Display",
        "Online Ordering System",
        "User Account Management",
        "Product Details",
        "Pagination",
        "Testimonials",
        "Contact Information",
        "User-Friendly Code",
        "Easy to customize",
        "SSL Certificate",
        "SSL Security",
        "Shopping Cart",
        "Order Tracking"
    ],
    "tr": [
        // CSS ve Stil Özellikleri
        "Tamamen Duyarlı",
        "Akıcı Düzen",
        "Esnek Izgaralar",
        "Medya Sorguları",
        "Esnek Görseller",
        "Viewport Meta Etiketi",
        "Mobil Navigasyon",
        "Kırılma Noktaları",
        "Dokunmatik Ekran Optimizasyonu",
        "Cihaz Uyumluluğu",
        "Akıcı Tipografi",
        "Esnek Formlar",
        "CSS Flexbox ve Grid Düzenleri",
        "Cihaz Yönü Desteği",
        "Duyarlı Görseller",
        "Tarayıcılar Arası Uyumluluk",
        "Esnek Metin",
        "Akıcı Animasyonlar ve Geçişler",
        "Duyarlı Tipografi",
        "Ölçeklenebilir Vektör Grafikleri",
        "Temiz ve basit tasarım",
        "Google Yazı Tipleri",
        "Düzen – Duyarlı",

        // E-ticaret Özellikleri
        "E-ticaret İşlevselliği",
        "Canlı Ürün Güncellemeleri",
        "Gelişmiş Filtreleme ve Sıralama",
        "Performans Optimizasyonu",
        "Performans Bütçeleme",
        "Dokunma Hareketleri ve Etkileşimler",
        "Dinamik İçerik Yükleme",
        "Uyarlanabilir Navigasyon Menüsü",
        "Form Optimizasyonu",
        "Arama Motoru",
        "Çoklu Platform Uyumluluğu",
        "Erişilebilirlik Özellikleri",
        "Menü Görüntüleme",
        "Çevrimiçi Sipariş Sistemi",
        "Kullanıcı Hesap Yönetimi",
        "Ürün Detayları",
        "Sayfalama",
        "Referanslar",
        "İletişim Bilgileri",
        "Kullanıcı Dostu Kod",
        "Kolay özelleştirme",
        "SSL Sertifikası",
        "SSL Güvenliği",
        "Alışveriş Sepeti",
        "Sipariş Takibi"
    ],
    "fa": [
        // ویژگی‌های CSS و طراحی
        "کاملاً واکنش‌گرا",
        "چیدمان سیال",
        "شبکه‌های انعطاف‌پذیر",
        "پرس‌وجوهای رسانه‌ای",
        "تصاویر انعطاف‌پذیر",
        "برچسب متا Viewport",
        "ناوبری موبایل",
        "نقاط شکست",
        "بهینه‌سازی صفحه نمایش لمسی",
        "سازگاری با دستگاه‌ها",
        "تایپوگرافی سیال",
        "فرم‌های انعطاف‌پذیر",
        "چیدمان‌های CSS Flexbox و Grid",
        "پشتیبانی از جهت‌گیری دستگاه",
        "تصاویر واکنش‌گرا",
        "سازگاری بین مرورگرها",
        "متن انعطاف‌پذیر",
        "انیمیشن‌ها و انتقالات سیال",
        "تایپوگرافی واکنش‌گرا",
        "گرافیک‌های برداری مقیاس‌پذیر",
        "طراحی تمیز و ساده",
        "فونت‌های گوگل",
        "چیدمان – واکنش‌گرا",

        // ویژگی‌های تجارت الکترونیک
        "قابلیت تجارت الکترونیک",
        "به‌روزرسانی‌های زنده محصولات",
        "فیلتر و مرتب‌سازی پیشرفته",
        "بهینه‌سازی عملکرد",
        "بودجه‌بندی عملکرد",
        "اشارات و تعاملات لمسی",
        "بارگذاری محتوای پویا",
        "منوهای ناوبری تطبیقی",
        "بهینه‌سازی فرم",
        "موتور جستجو",
        "سازگاری چند پلتفرمی",
        "ویژگی‌های دسترسی‌پذیری",
        "نمایش منو",
        "سیستم سفارش آنلاین",
        "مدیریت حساب کاربری",
        "جزئیات محصول",
        "صفحه‌بندی",
        "نظرات مشتریان",
        "اطلاعات تماس",
        "کد کاربر پسند",
        "سفارشی‌سازی آسان",
        "گواهی SSL",
        "امنیت SSL",
        "سبد خرید",
        "پیگیری سفارش"
    ]
};
const SecondaryEcommerceFeature = {
    "en": [
        // CSS and Styling Features
        "Fully Responsive",
        "Fluid Layout",
        "Flexible Grids",
        "Media Queries",
        "Flexible Images",
        "Viewport Meta Tag",
        "Mobile Navigation",
        "Breakpoints",
        "Touchscreen Optimization",
        "Device Compatibility",
        "Fluid Typography",
        "Flexible Forms",
        "CSS Flexbox and Grid Layouts",
        "Device Orientation Support",
        "Responsive Images",
        "Cross-Browser Compatibility",
        "Flexible Text",
        "Fluid Animations and Transitions",
        "Responsive Typography",
        "Scalable Vector Graphics",
        "Neat, clean and simple design",
        "Google Fonts",
        "Layout – Responsive",

        // E-commerce Features
        "E-commerce Functionality",
        "Advanced Filtering and Sorting",
        "Performance Optimization",
        "Performance Budgeting",
        "Touch Gestures and Interactions",
        "Dynamic Content Loading",
        "Adaptive Navigation Menus",
        "Form Optimization",
        "Search Engine",
        "Multi-platform Compatibility",
        "Accessibility Features",
        "Menu Display",
        "Product Details",
        "Product Zoom",
        "Pagination",
        "Contact Information",
        "User-Friendly Code",
        "Easy to customize",
        "SSL Certificate",
        "SSL Security",
    ],
    "tr": [
        // CSS ve Stil Özellikleri
        "Tamamen Duyarlı",
        "Akıcı Düzen",
        "Esnek Izgaralar",
        "Medya Sorguları",
        "Esnek Görseller",
        "Viewport Meta Etiketi",
        "Mobil Navigasyon",
        "Kırılma Noktaları",
        "Dokunmatik Ekran Optimizasyonu",
        "Cihaz Uyumluluğu",
        "Akıcı Tipografi",
        "Esnek Formlar",
        "CSS Flexbox ve Grid Düzenleri",
        "Cihaz Yönü Desteği",
        "Duyarlı Görseller",
        "Tarayıcılar Arası Uyumluluk",
        "Esnek Metin",
        "Akıcı Animasyonlar ve Geçişler",
        "Duyarlı Tipografi",
        "Ölçeklenebilir Vektör Grafikleri",
        "Temiz ve basit tasarım",
        "Google Yazı Tipleri",
        "Düzen – Duyarlı",

        // E-ticaret Özellikleri
        "E-ticaret İşlevselliği",
        "Gelişmiş Filtreleme ve Sıralama",
        "Performans Optimizasyonu",
        "Performans Bütçeleme",
        "Dokunma Hareketleri ve Etkileşimler",
        "Dinamik İçerik Yükleme",
        "Uyarlanabilir Navigasyon Menüsü",
        "Form Optimizasyonu",
        "Arama Motoru",
        "Çoklu Platform Uyumluluğu",
        "Erişilebilirlik Özellikleri",
        "Menü Görüntüleme",
        "Ürün Detayları",
        "Ürün Yakınlaştırma",
        "Sayfalama",
        "İletişim Bilgileri",
        "Kullanıcı Dostu Kod",
        "Kolay özelleştirme",
        "SSL Sertifikası",
        "SSL Güvenliği"
    ],
    "fa": [
        // ویژگی‌های CSS و طراحی
        "کاملاً واکنش‌گرا",
        "چیدمان سیال",
        "شبکه‌های انعطاف‌پذیر",
        "پرس‌وجوهای رسانه‌ای",
        "تصاویر انعطاف‌پذیر",
        "برچسب متا Viewport",
        "ناوبری موبایل",
        "نقاط شکست",
        "بهینه‌سازی صفحه نمایش لمسی",
        "سازگاری با دستگاه‌ها",
        "تایپوگرافی سیال",
        "فرم‌های انعطاف‌پذیر",
        "چیدمان‌های CSS Flexbox و Grid",
        "پشتیبانی از جهت‌گیری دستگاه",
        "تصاویر واکنش‌گرا",
        "سازگاری بین مرورگرها",
        "متن انعطاف‌پذیر",
        "انیمیشن‌ها و انتقالات سیال",
        "تایپوگرافی واکنش‌گرا",
        "گرافیک‌های برداری مقیاس‌پذیر",
        "طراحی تمیز و ساده",
        "فونت‌های گوگل",
        "چیدمان – واکنش‌گرا",

        // ویژگی‌های تجارت الکترونیک
        "قابلیت تجارت الکترونیک",
        "فیلتر و مرتب‌سازی پیشرفته",
        "بهینه‌سازی عملکرد",
        "بودجه‌بندی عملکرد",
        "اشارات و تعاملات لمسی",
        "بارگذاری محتوای پویا",
        "منوهای ناوبری تطبیقی",
        "بهینه‌سازی فرم",
        "موتور جستجو",
        "سازگاری چند پلتفرمی",
        "ویژگی‌های دسترسی‌پذیری",
        "نمایش منو",
        "جزئیات محصول",
        "بزرگنمایی محصول",
        "صفحه‌بندی",
        "اطلاعات تماس",
        "کد کاربر پسند",
        "سفارشی‌سازی آسان",
        "گواهی SSL",
        "امنیت SSL"
    ]
};
const ResponsiveWebsiteFeature = {
    "en": [
        "Fully Responsive",
        "Live Product Updates",
        "Advanced Filtering and Sorting",
        "E-commerce Functionality",
        "Fluid Layout",
        "Flexible Grids",
        "Media Queries",
        "Flexible Images",
        "Viewport Meta Tag",
        "Mobile Navigation",
        "Breakpoints",
        "Touchscreen Optimization",
        "Performance Optimization",
        "Device Compatibility",
        "Fluid Typography",
        "Flexible Forms",
        "CSS Flexbox and Grid Layouts",
        "Device Orientation Support",
        "Responsive Images",
        "Cross-Browser Compatibility",
        "Flexible Text",
        "Performance Budgeting",
        "Responsive Typography",
        "Scalable Vector Graphics",
        "Adaptive Navigation Menus",
        "Fluid Animations and Transitions",
        "Form Optimization",
        "Multi-platform Compatibility",
        "Accessibility Features",
        "Neat, clean and simple design",
        "Google Fonts",
        "Easy to customize",
        "SSL Certificate",
        "SSL Security"
    ],
    "tr": [
        "Tam Duyarlı",
        "Canlı Ürün Güncellemeleri",
        "Gelişmiş Filtreleme ve Sıralama",
        "E-ticaret Fonksiyonelliği",
        "Akışkan Düzen",
        "Esnek Izgaralar",
        "Medya Sorguları",
        "Esnek Görseller",
        "Viewport Meta Etiketi",
        "Mobil Navigasyon",
        "Kırılma Noktaları",
        "Dokunmatik Ekran Optimizasyonu",
        "Performans Optimizasyonu",
        "Cihaz Uyumluluğu",
        "Akışkan Tipografi",
        "Esnek Formlar",
        "CSS Flexbox ve Grid Düzenleri",
        "Cihaz Yönlendirme Desteği",
        "Duyarlı Görseller",
        "Tarayıcılar Arası Uyumluluk",
        "Esnek Metin",
        "Performans Bütçesi",
        "Duyarlı Tipografi",
        "Ölçeklenebilir Vektör Grafikler",
        "Uyarlanabilir Navigasyon Menüsü",
        "Akışkan Animasyonlar ve Geçişler",
        "Form Optimizasyonu",
        "Çoklu Platform Uyumluluğu",
        "Erişilebilirlik Özellikleri",
        "Temiz, düzenli ve basit tasarım",
        "Google Yazı Tipleri",
        "Kolay Özelleştirilebilir",
        "SSL Sertifikası",
        "SSL Güvenliği"
    ],
    "fa": [
        "کاملاً پاسخگو",
        "به‌روزرسانی‌های زنده محصولات",
        "فیلتر و مرتب‌سازی پیشرفته",
        "عملکرد تجارت الکترونیک",
        "چیدمان سیال",
        "شبکه‌های انعطاف‌پذیر",
        "پرسش‌های رسانه‌ای",
        "تصاویر انعطاف‌پذیر",
        "برچسب متا Viewport",
        "ناوبری موبایل",
        "نقاط شکست",
        "بهینه‌سازی صفحه لمسی",
        "بهینه‌سازی عملکرد",
        "سازگاری با دستگاه‌ها",
        "تایپوگرافی سیال",
        "فرم‌های انعطاف‌پذیر",
        "چیدمان‌های CSS Flexbox و Grid",
        "پشتیبانی از جهت‌گیری دستگاه",
        "تصاویر پاسخگو",
        "سازگاری بین مرورگرها",
        "متن انعطاف‌پذیر",
        "بودجه‌بندی عملکرد",
        "تایپوگرافی پاسخگو",
        "گرافیک‌های برداری مقیاس‌پذیر",
        "منوهای ناوبری تطبیقی",
        "انیمیشن‌ها و انتقال‌های سیال",
        "بهینه‌سازی فرم",
        "سازگاری چند پلتفرمی",
        "ویژگی‌های دسترسی‌پذیری",
        "طراحی ساده، مرتب و تمیز",
        "فونت‌های گوگل",
        "آسان برای سفارشی‌سازی",
        "گواهینامه SSL",
        "امنیت SSL"
    ]
};

export const ProjectsList = [
    // Rivix Restaurant E-Commerce
    {
        "id": 0,
        "title": {
            "en": "Rivix Electronics E-Commerce",
            "tr": "Rivix Elektronik E-Ticaret",
            "fa": "فروشگاه اینترنتی الکترونیک Rivix"
        },
        "sub": {
            "en": "Responsive E-Commerce Website",
            "tr": "Duyarlı E-Ticaret Web Sitesi",
            "fa": "وب‌سایت تجارت الکترونیک واکنش‌گرا"
        },
        "description": {
            "en": "Created a comprehensive electronics e-commerce website facilitating seamless browsing, comparison, and purchase of a diverse range of electronic products. Featured user-friendly interfaces, detailed product descriptions, secure payment processing, and responsive customer support for an enhanced shopping experience.",
            "tr": "Elektronik ürünlerin sorunsuz bir şekilde gezinmesini, karşılaştırılmasını ve satın alınmasını sağlayan kapsamlı bir elektronik e-ticaret web sitesi oluşturuldu. Kullanıcı dostu arayüzler, detaylı ürün açıklamaları, güvenli ödeme işlemleri ve gelişmiş alışveriş deneyimi için duyarlı müşteri desteği sunuldu.",
            "fa": "یک وب‌سایت جامع فروشگاه اینترنتی برای محصولات الکترونیکی طراحی شد که امکان مرور، مقایسه و خرید طیف متنوعی از محصولات الکترونیکی را فراهم می‌کرد. این وب‌سایت شامل رابط‌های کاربرپسند، توضیحات دقیق محصولات، پردازش امن پرداخت و پشتیبانی مشتری واکنش‌گرا برای تجربه بهتر خرید بود."
        },
        "feature": PrimaryEcommerceFeature
        ,
        "livePreview": "",
        "img": [
            "https://i.postimg.cc/0jdL5txM/1.png",
            "https://i.postimg.cc/2jQpx5vk/2.png",
            "https://i.postimg.cc/fLdGpzrv/4.png",
            "https://i.postimg.cc/fLdGpzrv/4.png",
            "https://i.postimg.cc/GhdWg1Jk/6.png",
            "https://i.postimg.cc/QdMRskzC/7.png",
            "https://i.postimg.cc/fTC66gB1/8.png"
        ],
        "mp4": "https://firebasestorage.googleapis.com/v0/b/my-portfolio-ee8ae.appspot.com/o/RIVIX%20VIDEO_1.mp4?alt=media&token=f6043b8b-3c83-45da-9d5f-8189fa28eedb"
    }

    // Beauty Restaurant
    ,
    {
        "id": 1,
        "title": {
            "en": "Responsive Beauty Website",
            "tr": "Duyarlı Güzellik Web Sitesi",
            "fa": "وب سایت زیبایی واکنش گرا"
        },
        "sub": {
            "en": "Responsive Website",
            "tr": "Duyarlı Web Sitesi",
            "fa": "وب سایت واکنش گرا"
        },
        "description": {
            "en": "Responsive beauty website designed to showcase beauty products, services, and information while ensuring optimal user experience across various devices. With its responsive design, the website adjusts seamlessly to different screen sizes, providing easy navigation and readability.\\n With both dark and light mode options. This allows users to select their preferred viewing experience, whether they prefer a bright, airy interface or a more subdued, night-friendly ambiance.",
            "tr": "Çeşitli cihazlarda optimum kullanıcı deneyimi sağlarken güzellik ürünlerini, hizmetlerini ve bilgilerini sergilemek için tasarlanmış duyarlı güzellik web sitesi. Duyarlı tasarımıyla web sitesi farklı ekran boyutlarına sorunsuz bir şekilde uyum sağlar, kolay gezinme ve okunabilirlik sağlar.\\n Hem koyu hem de açık mod seçenekleriyle. Bu, kullanıcıların parlak, havadar bir arayüz veya daha sakin, gece dostu bir ortam tercih edip etmedikleri fark etmeksizin tercih ettikleri görüntüleme deneyimini seçmelerine olanak tanır.",
            "fa": "وب سایت زیبایی واکنشگرا طراحی شده است تا محصولات، خدمات و اطلاعات زیبایی را به نمایش بگذارد و در عین حال از تجربه کاربری بهینه در دستگاه های مختلف اطمینان حاصل کند. با طراحی واکنش‌گرا، وب‌سایت به‌طور یکپارچه با اندازه‌های مختلف صفحه تنظیم می‌شود و پیمایش و خوانایی آسان را فراهم می‌کند.\\n با گزینه‌های حالت تاریک و روشن. این به کاربران اجازه می‌دهد تا تجربه تماشای دلخواه خود را انتخاب کنند، چه یک رابط روشن و مطبوع را ترجیح دهند یا یک محیط آرام‌تر و دوست‌دار شب."
        },
        "feature": SecondaryEcommerceFeature
        ,
        "livePreview": "https://beauty-01.netlify.app",
        "img": [
            "https://i.postimg.cc/Y94126DQ/Main.png",
            "https://i.postimg.cc/hjHVXXN6/Desktop.png",
            "https://i.postimg.cc/HnXXXpgx/Mobile.png"
        ],
        "mp4": ""
    }
    
    // Quilix Restaurant E-Commerce
    ,
    {
        "id": 2,
        "title": {
            "en": "Quilix Restaurant E-Commerce",
            "tr": "Quilix Restoran E-Ticaret",
            "fa": "تجارت الکترونیک رستوران Rivix"
        },
        "sub": {
            "en": "Responsive E-Commerce Website",
            "tr": "Duyarlı E-Ticaret Web Sitesi",
            "fa": "وب‌سایت تجارت الکترونیک واکنش‌گرا"
        },
        "description": {
            "en": "Restaurant e-commerce website with a language selector enables customers to explore the menu, place orders, and select their preferred language for navigation. The website simplifies the ordering process, allowing users to browse through menu items, make selections, and submit orders seamlessly. With the language selector feature, the website caters to diverse linguistic preferences, enhancing accessibility and user experience.",
            "tr": "Dil seçicisi olan restoran e-ticaret web sitesi, müşterilerin menüyü keşfetmesini, sipariş vermesini ve gezinme için tercih ettikleri dili seçmesini sağlar. Web sitesi sipariş verme sürecini basitleştirir, kullanıcıların menü öğelerine göz atmasını, seçimler yapmasını ve siparişleri sorunsuz bir şekilde göndermesini sağlar. Dil seçici özelliğiyle web sitesi çeşitli dil tercihlerine hitap ederek erişilebilirliği ve kullanıcı deneyimini geliştirir.",
            "fa": "وب‌سایت تجارت الکترونیک رستوران با انتخابگر زبان، مشتریان را قادر می‌سازد تا منو را بررسی کنند، سفارش دهند و زبان دلخواه خود را برای پیمایش انتخاب کنند. این وب‌سایت فرآیند سفارش را ساده می‌کند و به کاربران این امکان را می‌دهد که آیتم‌های منو را مرور کنند، انتخاب کنند و سفارش‌ها را به صورت یکپارچه ارسال کنند. با ویژگی انتخاب زبان، وب سایت به اولویت های زبانی متنوع پاسخ می دهد و دسترسی و تجربه کاربر را افزایش می دهد."
        },
        "feature": SecondaryEcommerceFeature
        ,
        "livePreview": "",
        "img": [
            "https://i.postimg.cc/vHc8TPG1/Main.png",
            "https://i.postimg.cc/rpLqhMxW/Desktop.png",
            "https://i.postimg.cc/5NT4Cwts/Responsivity.png"
        ],
        "mp4": ""
    }

    
    // Blivix Restaurant E-Commerce
    ,
    {
        "id": 3,
        "title": {
            "en": "Blivix Restaurant E-Commerce",
            "tr": "Blivix Restoran E-Ticaret",
            "fa": "تجارت الکترونیک رستوران Blivix"
        },
        "sub": {
            "en": "Responsive E-Commerce Website with Language Selector",
            "tr": "Dil Seçicili Duyarlı E-Ticaret Web Sitesi",
            "fa": "وب سایت تجارت الکترونیک واکنش گرا با انتخابگر زبان"
        },
        "description": {
            "en": "Restaurant e-commerce website with a language selector enables customers to explore the menu, place orders, and select their preferred language for navigation. The website simplifies the ordering process, allowing users to browse through menu items, make selections, and submit orders seamlessly. With the language selector feature, the website caters to diverse linguistic preferences, enhancing accessibility and user experience.",
            "tr": "Dil seçicisi olan restoran e-ticaret web sitesi, müşterilerin menüyü keşfetmesini, sipariş vermesini ve gezinme için tercih ettikleri dili seçmesini sağlar. Web sitesi sipariş verme sürecini basitleştirir, kullanıcıların menü öğelerine göz atmasını, seçimler yapmasını ve siparişleri sorunsuz bir şekilde göndermesini sağlar. Dil seçici özelliğiyle web sitesi çeşitli dil tercihlerine hitap ederek erişilebilirliği ve kullanıcı deneyimini geliştirir.",
            "fa": "وب‌سایت تجارت الکترونیک رستوران با انتخابگر زبان، مشتریان را قادر می‌سازد تا منو را بررسی کنند، سفارش دهند و زبان دلخواه خود را برای پیمایش انتخاب کنند. این وب‌سایت فرآیند سفارش را ساده می‌کند و به کاربران این امکان را می‌دهد که آیتم‌های منو را مرور کنند، انتخاب کنند و سفارش‌ها را به صورت یکپارچه ارسال کنند. با ویژگی انتخاب زبان، وب سایت به اولویت های زبانی متنوع پاسخ می دهد و دسترسی و تجربه کاربر را افزایش می دهد."
        },
        "feature": PrimaryEcommerceFeature
        ,
        "livePreview": "",
        "img": [
            "https://i.postimg.cc/7YkcQ9LC/Main.png",
            "https://i.postimg.cc/FFL561g6/Desktop-1.png",
            "https://i.postimg.cc/65xJxwMp/Desktop-2.png",
            "https://i.postimg.cc/sXtFXRyK/Desktop-4.png",
            "https://i.postimg.cc/xTGr07Zy/Desktop-5.png",
            "https://i.postimg.cc/dVnzCCwh/Desktop-6.png",
            "https://i.postimg.cc/cJHPBJLX/Desktop-7.png",
            "https://i.postimg.cc/0y9TrnRg/Desktop-8.png",
            "https://i.postimg.cc/XYNPnvd4/Mobile-1.png",
            "https://i.postimg.cc/DyPDVGWx/Mobile-2.png",
            "https://i.postimg.cc/t4rMJHNP/Mobile-3.png",
            "https://i.postimg.cc/fbJg0g8P/Mobile-4.png",
            "https://i.postimg.cc/1z270cT3/Mobile-5.png",
            "https://i.postimg.cc/ZRN7wvtF/Mobile-6.png"
        ],
        "mp4": ""
    }

    
    // Flerko Restaurant E-Commerce
    ,
    {
        "id": 4,
        "title": {
            "en": "Flerko Restaurant E-Commerce",
            "tr": "Flerko Restoran E-Ticaret",
            "fa": "تجارت الکترونیک رستوران Flerko"
        },
        "sub": {
            "en": "Responsive E-Commerce Website",
            "tr": "Duyarlı E-Ticaret Web Sitesi",
            "fa": "وب‌سایت تجارت الکترونیک واکنش‌گرا"
        },
        "description": {
            "en": "",
            "tr": "",
            "fa": ""
        },
        "feature": SecondaryEcommerceFeature
        ,
        "livePreview": "https://flerko.netlify.app",
        "img": [
            "https://i.postimg.cc/Nfb39n66/flerko-en-home.png",
            "https://i.postimg.cc/MGfNLKh5/flerko-en-desktop.png",
            "https://i.postimg.cc/YCsT8myj/flerko-en-mobile-01.png",
            "https://i.postimg.cc/4xdrz1Pr/flerko-en-mobile-02.png",
            "https://i.postimg.cc/C1yXpp0g/flerko-en-mobile-03.png",
            "https://i.postimg.cc/PxmctVf8/flerko-en-mobile-04.png",

            "https://i.postimg.cc/nrq0KX9j/flerko-tr-home.png",
            "https://i.postimg.cc/NM3dr6Fv/flerko-tr-desktop.png",
            "https://i.postimg.cc/HWMZ1hvY/flerko-tr-mobile-01.png",
            "https://i.postimg.cc/fThHzf1x/flerko-tr-mobile-02.png",
            "https://i.postimg.cc/wTcw7sdn/flerko-tr-mobile-03.png",
            "https://i.postimg.cc/0ykcm2kj/flerko-tr-mobile-04.png",
            
            "https://i.postimg.cc/50dzBnBg/flerko-fa-home.png",
            "https://i.postimg.cc/cCT8NWVk/flerko-fa-desktop.png",
            "https://i.postimg.cc/j2RJ92kJ/flerko-fa-mobile-01.png",
            "https://i.postimg.cc/B6TKMHN0/flerko-fa-mobile-02.png",
            "https://i.postimg.cc/1RKFF0f0/flerko-fa-mobile-03.png",
            "https://i.postimg.cc/kXXbvVb0/flerko-fa-mobile-04.png"
        ],
        "mp4": ""
    }

    
    // Quazbit Restaurant E-Commerce
    ,
    {
        "id": 5,
        "title": {
            "en": "Quazbit Restaurant E-Commerce",
            "tr": "Quazbit Restoran E-Ticaret",
            "fa": "تجارت الکترونیک رستوران Quazbit"
        },
        "sub": {
            "en": "Responsive E-Commerce Website",
            "tr": "Duyarlı E-Ticaret Web Sitesi",
            "fa": "وب‌سایت تجارت الکترونیک واکنش‌گرا"
        },
        "description": {
            "en": "",
            "tr": "",
            "fa": ""
        },
        "feature": SecondaryEcommerceFeature
        ,
        "livePreview": "https://quazbit.netlify.app",
        "img": [
            "https://i.postimg.cc/1zJx6j1h/quazbit-en-home-01.png",
            "https://i.postimg.cc/hjjk01KJ/quazbit-en-home-02.png",
            "https://i.postimg.cc/QCKv2N9h/quazbit-en-desktop-01.png",
            "https://i.postimg.cc/DZMtp4zx/quazbit-en-desktop-02.png",
            "https://i.postimg.cc/QVLvhL15/quazbit-en-mobile-01.png",
            "https://i.postimg.cc/tR1TqhFC/quazbit-en-mobile-02.png",
            "https://i.postimg.cc/BZdvXXC5/quazbit-en-mobile-03.png",
            "https://i.postimg.cc/Z5mKx22C/quazbit-en-mobile-04.png",
            "https://i.postimg.cc/kgyM1wPw/quazbit-en-mobile-05.png",
            "https://i.postimg.cc/BvQSdDq3/quazbit-en-mobile-06.png",

            "https://i.postimg.cc/P50y7bbp/quazbit-tr-home-01.png",
            "https://i.postimg.cc/FR4Zr9Bh/quazbit-tr-home-02.png",
            "https://i.postimg.cc/W1dSSYZc/quazbit-tr-desktop-01.png",
            "https://i.postimg.cc/fbXCQ35H/quazbit-tr-desktop-02.png",
            "https://i.postimg.cc/63fhTDkn/quazbit-tr-mobile-01.png",
            "https://i.postimg.cc/SRZ7fcNK/quazbit-tr-mobile-02.png",
            "https://i.postimg.cc/5y9SzsJG/quazbit-tr-mobile-03.png",
            "https://i.postimg.cc/rygJT2CL/quazbit-tr-mobile-04.png",
            "https://i.postimg.cc/QxtqxY0K/quazbit-tr-mobile-05.png",
            "https://i.postimg.cc/8P0mFd0K/quazbit-tr-mobile-06.png",
            
            "https://i.postimg.cc/76hLTzc9/quazbit-fa-home-01.png",
            "https://i.postimg.cc/ThM2S4PN/quazbit-fa-home-02.png",
            "https://i.postimg.cc/dQ78bZ1G/quazbit-fa-mobile-01.png",
            "https://i.postimg.cc/NGW1BG9S/quazbit-fa-mobile-02.png",
            "https://i.postimg.cc/fRsmSHvv/quazbit-fa-mobile-03.png",
            "https://i.postimg.cc/N0fXp5S9/quazbit-fa-mobile-04.png",
            "https://i.postimg.cc/T3cmVr7v/quazbit-fa-mobile-05.png",
            "https://i.postimg.cc/Pq3wcdn6/quazbit-fa-mobile-06.png",
        ],
        "mp4": ""
    }

    
    // Zilvix Restaurant E-Commerce
    ,
    {
        "id": 6,
        "title": {
            "en": "Zilvix Clothes E-Commerce",
            "tr": "Zilvix Giyim E-Ticaret",
            "fa": "تجارت الکترونیکی لباس Zilvix"
        },
        "sub": {
            "en": "Responsive E-Commerce Website",
            "tr": "Duyarlı E-Ticaret Web Sitesi",
            "fa": "وب‌سایت تجارت الکترونیک واکنش‌گرا"
        },
        "description": {
            "en": "",
            "tr": "",
            "fa": ""
        },
        "feature": PrimaryEcommerceFeature
        ,
        "livePreview": "",
        "img": [
            "https://i.postimg.cc/05JVpBpg/zilvix-en-home.png",
            
            "https://i.postimg.cc/XJrQ12mC/zilvix-en-desktop-01.png",
            "https://i.postimg.cc/BvQhHZCN/zilvix-en-desktop-02.png",
            "https://i.postimg.cc/kMVpT4Pp/zilvix-en-desktop-03.png",
            "https://i.postimg.cc/ZRQ694f9/zilvix-en-desktop-04.png",

            "https://i.postimg.cc/VLQD4WRJ/zilvix-en-mobile-01.png",
            "https://i.postimg.cc/PxvQQCvb/zilvix-en-mobile-02.png",
            "https://i.postimg.cc/9FhYyFmT/zilvix-en-mobile-03.png",
            "https://i.postimg.cc/T32jH7W3/zilvix-en-mobile-04.png",

            "https://i.postimg.cc/wxQwVNnk/zilvix-tr-home.png",
            "https://i.postimg.cc/x8ntQjNH/zilvix-tr-desktop-01.png",
            "https://i.postimg.cc/gJvN1Ljm/zilvix-tr-desktop-02.png",
            "https://i.postimg.cc/Tw8tkJRt/zilvix-tr-desktop-03.png",
            "https://i.postimg.cc/TYQHRb9P/zilvix-tr-desktop-04.png",
            "https://i.postimg.cc/5N9nk0VW/zilvix-tr-mobile-01.png",
            "https://i.postimg.cc/TYkC86dL/zilvix-tr-mobile-02.png",
            "https://i.postimg.cc/zGVp8cvd/zilvix-tr-mobile-04.png",
            "https://i.postimg.cc/6pgc3fGB/zilvix-tr-mobile-04-1.png",

            "https://i.postimg.cc/bNspfqx4/zilvix-fa-home.png",
            "https://i.postimg.cc/QMLXqvWw/zilvix-fa-desktop-01.png",
            "https://i.postimg.cc/65356rXj/zilvix-fa-desktop-02.png",
            "https://i.postimg.cc/HnWWy57L/zilvix-fa-desktop-03.png",
            "https://i.postimg.cc/dVh168Nk/zilvix-fa-desktop-04.png",
            "https://i.postimg.cc/Cx80dJ6N/zilvix-fa-mobile-01.png",
            "https://i.postimg.cc/SQtbrHBd/zilvix-fa-mobile-02.png",
            "https://i.postimg.cc/qMhVZj0T/zilvix-fa-mobile-03.png",
            "https://i.postimg.cc/ZKGtkpKy/zilvix-fa-mobile-04.png"


        ],
        "mp4": ""
    }

    
    // Zentrix Restaurant E-Commerce
    ,
    {
        "id": 7,
        "title": {
            "en": "Zentrix Website",
            "tr": "Zentrix Web Sitesi",
            "fa": "وب سایت Zentrix"
        },
        "sub": {
            "en": "Responsive Website",
            "tr": "Duyarlı web sitesi",
            "fa": "وب سایت واکنش گرا"
        },
        "description": {
            "en": "",
            "tr": "",
            "fa": ""
        },
        "feature": ResponsiveWebsiteFeature
        ,
        "livePreview": "https://zentrix-01.netlify.app/",
        "img": [
            "https://i.postimg.cc/rsN9bjNx/quazbit-home-01.png",
            "https://i.postimg.cc/vZBvqWgx/quazbit-desktop.png",
            "https://i.postimg.cc/65z0fH3N/quazbit-mobile-01.png",
            "https://i.postimg.cc/zv8CnWYB/quazbit-mobile-02.png",
            "https://i.postimg.cc/MZmbzs1j/quazbit-mobile-03.png"
        ],
        "mp4": ""
    }

    


]


