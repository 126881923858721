import React from 'react';
import './Footer.css';
import { FaFacebook, FaLinkedin, FaPaperPlane, FaInstagramSquare, FaDribbble } from "react-icons/fa";
import Logo from '../../assest/logo.png';
import { Link } from "react-router-dom";

function Footer({ scrollToTop, selectedLanguage}) {
    const handleOpenMailApp = () => {
        const emailAddress = 'nacode@nacode.co';
        const subject = 'Projects Name';
        const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`;
        window.location.href = mailtoLink;
    };

    return (
        <footer className="footer" lang={selectedLanguage === 'fa' ? 'fa' : 'en'}
                dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
            <div className="footer_container container grid">

                <div className="footer_content">
                    <div>
                        <img src={Logo} alt="logo image" className='footer_logo'/>
                        <p className="footer_description">
                            <span>
                                {selectedLanguage === 'en' ? 'Elevate your online presence with Nacode. Our passion is creating visually stunning and user-friendly websites. If you have a project in mind or questions for us, drop us an email at info@nacode.co . We are here to help!'
                                    : selectedLanguage === 'tr' ? 'Nacode ile çevrimiçi varlığınızı yükseltin. Tutkumuz, görsel olarak çarpıcı ve kullanıcı dostu web siteleri oluşturmaktır. Aklınızda bir proje veya bize sorularınız varsa, bize info@nacode.co adresinden bir e-posta bırakın. Yardım etmek için buradayız!'
                                        : selectedLanguage === 'fa' ? 'با Nacode حضور آنلاین خود را ارتقا دهید. اشتیاق ما ایجاد وب‌سایت‌های بصری زیبا و کاربرپسند است. اگر پروژه‌ای در ذهن دارید یا سوالاتی دارید، برای ما ایمیلی به info.webdev@gmail.com ارسال کنید. ما اینجا هستیم تا کمک کنیم!'
                                            : null
                                }
                            </span>
                        </p>
                    </div>

                    <div>
                        <h3 className="footer_title">
                            <span>
                                {selectedLanguage === 'en' ? 'Main Menu'
                                    : selectedLanguage === 'tr' ? 'Ana Menü'
                                        : selectedLanguage === 'fa' ? 'منوی اصلی'
                                            : null
                                }
                            </span>
                        </h3>

                        <ul className="footer_links">
                            <li>
                                <Link to='/' className="footer_link" onClick={scrollToTop}>
                                    <span>
                                        {selectedLanguage === 'en' ? 'Home'
                                            : selectedLanguage === 'tr' ? 'Ana Sayfa'
                                                : selectedLanguage === 'fa' ? 'خانه'
                                                    : null
                                        }
                                    </span>
                                </Link>
                            </li>

                            <li>
                                <Link to='/services' className="footer_link" onClick={scrollToTop}>
                                    <span>
                                        {selectedLanguage === 'en' ? 'Services'
                                            : selectedLanguage === 'tr' ? 'Hizmetler'
                                                : selectedLanguage === 'fa' ? 'خدمات'
                                                    : null
                                        }
                                    </span>
                                </Link>
                            </li>

                            <li>
                                <Link to='/projects' className="footer_link" onClick={scrollToTop}>
                                    <span>
                                        {selectedLanguage === 'en' ? 'Projects'
                                            : selectedLanguage === 'tr' ? 'Projeler'
                                                : selectedLanguage === 'fa' ? 'پروژه‌ها'
                                                    : null
                                        }
                                    </span>
                                </Link>
                            </li>

                            <li>
                                <Link to='/about' className="footer_link" onClick={scrollToTop}>
                                    <span>
                                        {selectedLanguage === 'en' ? 'About Us'
                                            : selectedLanguage === 'tr' ? 'Hakkımızda'
                                                : selectedLanguage === 'fa' ? 'درباره ما'
                                                    : null
                                        }
                                    </span>
                                </Link>
                            </li>

                            <li>
                                <Link to='/faq' className="footer_link" onClick={scrollToTop}>
                                    <span>
                                        {selectedLanguage === 'en' ? 'FAQ'
                                            : selectedLanguage === 'tr' ? 'SSS'
                                                : selectedLanguage === 'fa' ? 'سؤالات متداول'
                                                    : null
                                        }
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h3 className="footer_title">
                            <span>
                                {selectedLanguage === 'en' ? 'Legal & Privacy'
                                    : selectedLanguage === 'tr' ? 'Yasal & Gizlilik'
                                        : selectedLanguage === 'fa' ? 'قانونی و حریم خصوصی'
                                            : null
                                }
                            </span>
                        </h3>

                        <ul className="footer_links">
                            <li>
                                <Link to='/terms-conditions' className="footer_link" onClick={scrollToTop}>
                                    <span>
                                        {selectedLanguage === 'en' ? 'Terms and Conditions'
                                            : selectedLanguage === 'tr' ? 'Şartlar ve Koşullar'
                                                : selectedLanguage === 'fa' ? 'شرایط و ضوابط'
                                                    : null
                                        }
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link to='/privacy-policy' className="footer_link" onClick={scrollToTop}>
                                    <span>
                                        {selectedLanguage === 'en' ? 'Privacy Policy'
                                            : selectedLanguage === 'tr' ? 'Gizlilik Politikası'
                                                : selectedLanguage === 'fa' ? 'سیاست حفظ حریم خصوصی'
                                                    : null
                                        }
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <Link to='/cookies-privacy' className="footer_link" onClick={scrollToTop}>
                                    <span>
                                        {selectedLanguage === 'en' ? 'Cookies Privacy'
                                            : selectedLanguage === 'tr' ? 'Çerez Gizliliği'
                                                : selectedLanguage === 'fa' ? 'حریم خصوصی کوکی‌ها'
                                                    : null
                                        }
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h3 className="footer_title">
                            <span>
                                {selectedLanguage === 'en' ? 'Contact Us'
                                    : selectedLanguage === 'tr' ? 'Bize Ulaşın'
                                        : selectedLanguage === 'fa' ? 'تماس با ما'
                                            : null
                                }
                            </span>
                        </h3>

                        <Link to='/contact' className="footer_link " onClick={scrollToTop}>
                            <button className="contact_button button footer-contact-button">
                                <FaPaperPlane/>
                                <span>
                                    {selectedLanguage === 'en' ? 'Send Message'
                                        : selectedLanguage === 'tr' ? 'Mesaj Gönder'
                                            : selectedLanguage === 'fa' ? 'ارسال پیام'
                                                : null
                                    }
                                </span>
                            </button>
                        </Link>

                        <h3 className="footer_title">
                            <span>
                                {selectedLanguage === 'en' ? 'Social Media'
                                    : selectedLanguage === 'tr' ? 'Sosyal Medya'
                                        : selectedLanguage === 'fa' ? 'شبکه‌های اجتماعی'
                                            : null
                                }
                            </span>
                        </h3>

                        <div className="footer_social">
                            <a href="https://www.facebook.com/share/sq3BHocRxXnUFZoV/?mibextid=LQQJ4d"
                               target="_blank"
                               className="footer_social-link"
                               rel="noreferrer"
                            >
                                <FaFacebook/>
                            </a>

                            <a href="https://www.instagram.com/nacode.webdev/?igsh=NTZyemRkcjdsem05&utm_source=qr"
                               target="_blank"
                               className="footer_social-link"
                               rel="noreferrer"
                            >
                                <FaInstagramSquare/>
                            </a>

                            <a href="https://www.linkedin.com/in/nasirullah-ahmadi-64785b294?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                               target="_blank" className="footer_social-link" rel="noreferrer">
                                <FaLinkedin/>
                            </a>

                        </div>

                        <br/>

                        <h3 className="footer_title">
                            <span>
                                {selectedLanguage === 'en' ? 'Email Address'
                                    : selectedLanguage === 'tr' ? 'E-posta Adresi'
                                        : selectedLanguage === 'fa' ? 'ایمیل آدرس'
                                            : null
                                }
                            </span>
                        </h3>
                        
                        <span className=" footer_link" onClick={handleOpenMailApp}>
                            info@nacode.co
                        </span>

                    </div>
                </div>
            </div>
            <span className="footer_copy">
                        {selectedLanguage === 'en' ? '© 2024 Nacdoe. All rights reserved.'
                            : selectedLanguage === 'tr' ? '© 2024 Nacdoe. Tüm hakları saklıdır.'
                                : selectedLanguage === 'fa' ? '© 2024 Nacdoe. تمامی حقوق محفوظ است.'
                                    : null
                        }
            </span>
        </footer>
    );
}

export default Footer;
