import React from "react";
import './About.css';
import Random_lines from '../../assest/random lines.svg';
import VectorOne from '../../assest/about-us/vector-1.png';

import {FaGithub, FaInstagram, FaLinkedin} from "react-icons/fa";
import {Link} from "react-router-dom";
import PortfolioPhoto from '../../assest/Portfolio Photo.png';
import { FaCode } from "react-icons/fa";


function About ({selectedLanguage}) {
    const handleOpenMailApp = () => {
        // Change the email address and subject as needed
        const emailAddress = 'Nacode.webdev@gmail.com';
        const subject = 'Projects Name';

        // Create the mailto link
        const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`;

        // Open the default mail app
        window.location.href = mailtoLink;
    };

    return (
        <main className='main' lang={selectedLanguage === 'fa' ? 'fa' : 'en'} dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
            <section className="about_section " id="about">
                <h2 className="section_title-1 about-title">
                    <span>
                        {selectedLanguage === 'en' ? 'About Us'
                            : selectedLanguage === 'tr' ? 'Hakkımızda'
                                : selectedLanguage === 'fa' ? 'درباره ما'
                                    : null
                        }
                    </span>
                </h2>

                <div className="about-container container grid">
                    <p className='about-description-one'>
                        {selectedLanguage === 'en' ? 'We transform brands through elevated digital experiences'
                            : selectedLanguage === 'tr' ? 'Markaları dijital deneyimlerle dönüştürüyoruz'
                                : selectedLanguage === 'fa' ? 'ما برندها را از طریق تجربیات دیجیتال ارتقا یافته متحول می‌کنیم.'
                                    : null
                        }
                    </p>

                    {
                        /*
                        <div className="shape-one ">
                        <div className="shape-one-quarter"></div>
                        <div className="shape-one-quarter"></div>
                        <div className="shape-one-quarter"></div>
                        <div className="shape-one-quarter"></div>
                        </div>
                        */
                    }

                    <div className='shape-two-container'>
                        <FaCode className='shape-two' />
                    </div>


                    <div className="about-description-two">
                        {selectedLanguage === 'en' ? 'We\'re a global group of strategists, designers, and engineers building notable brands and premier digital experiences that captivate users worldwide. Our unified, AI-infused creative approach ensures scalability and consistency across all brand touch points by building robust design systems.'
                            : selectedLanguage === 'tr' ? 'Dünya çapında kullanıcıları büyüleyen dikkat çekici markalar ve birinci sınıf dijital deneyimler inşa eden küresel bir stratejistler, tasarımcılar ve mühendisler grubuyuz. Birleşik, yapay zeka destekli yaratıcı yaklaşımımız, sağlam tasarım sistemleri oluşturarak tüm marka temas noktalarında ölçeklenebilirlik ve tutarlılık sağlar.'
                                : selectedLanguage === 'fa' ? 'ما یک گروه جهانی از استراتژیست‌ها، طراحان و مهندسان هستیم که برندهای قابل توجه و تجربیات دیجیتال برتر را ایجاد می‌کنیم که کاربران را در سراسر جهان جذب می‌کند. رویکرد خلاقانه یکپارچه و مبتنی بر هوش مصنوعی ما اطمینان می‌دهد که مقیاس‌پذیری و ثبات در تمامی نقاط تماس برند حفظ شود و سیستم‌های طراحی قوی ایجاد کند.'
                                    : null
                        }
                    </div>

                    <div className='about-us-team'>
                        <h1 className='section_title-1 about-team-tittle '>
                            {selectedLanguage === 'en' ? 'Our Team'
                                : selectedLanguage === 'tr' ? 'Ekibimiz'
                                    : selectedLanguage === 'fa' ? 'تیم ما'
                                        : null
                            }
                        </h1>

                        <p className='about-team-description'>
                            {selectedLanguage === 'en' ? 'Meet the dedicated professionals behind Nacode:'
                                : selectedLanguage === 'tr' ? 'Nacode\'un arkasındaki özverili profesyonellerle tanışın:'
                                    : selectedLanguage === 'fa' ? 'با حرفه‌ای‌های متعهد نیکود آشنا شوید:'
                                        : null
                            }
                        </p>

                        <ul className='about-team-list'>
                            <li>
                                {selectedLanguage === 'en' ? 'Daniel Walker, Founder & CEO'
                                    : selectedLanguage === 'tr' ? 'Daniel Walker, Kurucusu ve CEO\'su'
                                        : selectedLanguage === 'fa' ? 'دنیل واکر، بنیان‌گذار و مدیر عامل'
                                            : null
                                }
                            </li>
                            <li>
                                {selectedLanguage === 'en' ? 'Abdul Haq Matin, Creative Director'
                                    : selectedLanguage === 'tr' ? 'Abdul Haq Matin, Yaratıcı Yönetmen'
                                        : selectedLanguage === 'fa' ? 'عبدالحق متین، مدیر خلاقیت'
                                            : null
                                }
                            </li>
                            <li>
                                {selectedLanguage === 'en' ? 'Freddy Parker, Lead Developer'
                                    : selectedLanguage === 'tr' ? 'Freddy Parker, Baş Geliştirici'
                                        : selectedLanguage === 'fa' ? 'فردی پارکر، توسعه‌دهندهٔ اصلی'
                                            : null
                                }
                            </li>
                            <li>
                                {selectedLanguage === 'en' ? 'Zahidullah Mohammadi, Client Director'
                                    : selectedLanguage === 'tr' ? 'Zahidullah Mohammadi, Müşteri Direktörü'
                                        : selectedLanguage === 'fa' ? 'زاهدالله محمدی، مدیر مشتری'
                                            : null
                                }
                            </li>
                        </ul>
                    </div>

                </div>
            </section>
        </main>
    );
}

export default About;

{/*
<div className="about_perfil">
                        <div className="about_image">
                            <img src={PortfolioPhoto} alt="pic" className="about_img"/>

                            <div className="about_shadow"></div>

                            <div className="geometric_box"></div>
                            <img src={Random_lines} alt="pic" className="about_line"/>
                            <div className="about_box"></div>
                        </div>
                    </div>

                    <div className="about_info">
                        <p className="about_description">
                            I'm a full-stack developer specialised in frontend and backend development for complex
                            scalable web apps.
                            Below is a quick overview of my main technical skill sets and tools I use.
                        </p>

                        <ul className="about_list">
                            <li className="about_item">
                                <b>Skills:</b> HTML CSS, SCSS, JavaScript, ReactJs, Redux.JS, Bootstrap, Stripe, Node.js, Express.Js, MongoDB, Firebase, Heroku, Netlify, GoDaddy, GitHub.
                            </li>
                        </ul>

                        <div className="about_buttons">
                            <button onClick={handleOpenMailApp} className="button contact_me-btn">
                                <i className="fa-regular fa-paper-plane"></i> Contact Me
                            </button>

                            <a href="https://www.instagram.com/nacode.webdev/?igsh=NTZyemRkcjdsem05&utm_source=qr"
                               target="_blank" className="button_ghost" rel="noreferrer">
                                <FaInstagram className='about_icon'/>
                            </a>

                            <a href="https://github.com/Nasirullahahmadi"
                               target="_blank" className="button_ghost" rel="noreferrer">
                                <FaGithub className='about_icon'/>
                            </a>

                            <a href="https://www.linkedin.com/in/nasirullah-ahmadi-64785b294?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                               target="_blank"
                               className="button_ghost" rel="noreferrer"
                            >

                                <FaLinkedin className='about_icon'/>
                            </a>

                        </div>

                    </div>
*/
}
