import React, { useState, useEffect, memo, useRef } from 'react';
import './ProjectDetails.css';
import { useParams } from 'react-router-dom';
import { ProjectsList } from "../../Projects List";
import { RecentProjectsList } from "../../Recent Projects List";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { FaRegCircleCheck } from "react-icons/fa6";
import { HiOutlineMinusSmall } from "react-icons/hi2";
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'; // Importing arrow icons
import AlertModal from '../Alert Modal/AlertModal';

// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Navigation } from 'swiper/modules';

function ProjectDetails({ selectedLanguage }) {
    const [projectDetails, setProjectDetails] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const { id } = useParams();

    // Refs for custom Swiper buttons
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    useEffect(() => {
        const combinedProjectsList = [...ProjectsList, ...RecentProjectsList];
        const details = combinedProjectsList.find(project => project.id === parseInt(id));
        setProjectDetails(details || {});
    }, [id]);

    const handleLivePreviewClick = (e) => {
        if (!projectDetails.livePreview) {
            e.preventDefault();
            setShowAlert(true);
        }
    };

    const closeAlert = () => setShowAlert(false);

    return (
        <main className='main' lang={selectedLanguage === 'fa' ? 'fa' : 'en'}>
            <section className='pd_section'>
                {projectDetails ? (
                    <div className='pd_container grid container'>
                        <div className='pd_content'>
                            <div className="productDetails-slider-container">
                                <div className='product-details-info'>
                                    <h2 className="pd_title">
                                        {projectDetails.title?.[selectedLanguage]}
                                    </h2>
                                    <div className="pd_buttons">
                                        <a
                                            href={projectDetails.livePreview || "#"}
                                            className="pd-live-link button"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={handleLivePreviewClick}
                                        >
                                            <BsFillGrid1X2Fill className="github_icon"/> Live Preview
                                        </a>
                                    </div>
                                    <p className="details-info-description">
                                        {projectDetails.description?.[selectedLanguage]}
                                    </p>
                                </div>
                                
                                <div className='project-details-swiper-container'>
                                    <Swiper
                                        modules={[Navigation]} // Use the Navigation module correctly
                                        navigation={true}
                                        style={{
                                            "--swiper-navigation-color": 'var(--black-color-light)',
                                            "--swiper-pagination-color": 'var(--black-color-light)'
                                        }}
                                        loop
                                        className="details-slider-container"
                                    >
                                        {projectDetails.img?.map((image, index) => (
                                            <SwiperSlide key={index}>
                                                <img
                                                    src={image}
                                                    alt={`Slide ${index + 1}`}
                                                    className="slider-image"
                                                    draggable="false"
                                                    onContextMenu={(e) => e.preventDefault()} // Prevent context menu on image
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                  
                                </div>

                               
                            </div>

                            <div className='pd_details' dir={selectedLanguage === 'fa' ? 'rtl' : 'ltr'}>
                                {showAlert && (
                                    <AlertModal
                                        message="Live preview is not available for this project."
                                        onClose={closeAlert}
                                    />
                                )}

                                <h2 className='feature_title'>
                                    {selectedLanguage === 'en' ? 'Features'
                                        : selectedLanguage === 'tr' ? 'Özellikler'
                                            : 'ویژگی ها'}
                                    <HiOutlineMinusSmall />
                                </h2>
                                <ul className='pd_feature-list'>
                                    {projectDetails.feature?.[selectedLanguage]?.map((feature, index) => (
                                        <li className='pd_feature-item' key={index}>
                                            <FaRegCircleCheck className='pd_feature-icon' /> <h4>{feature}</h4>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ) : (
                    <h1 className='services_title'>Product not found</h1>
                )}
            </section>
        </main>
    );
}

export default memo(ProjectDetails);
